import Apiservice from '../../api.service';
import { API_URL } from '../../../../common/config';
const apiService = Apiservice;

export default class AlumnusWorkExperienceService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/alumnus-work-experience`;
    }
    createOrUpdate(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data)
    }

    showByAlumnus(alumnusId){
        let url = `${this.#api}/${alumnusId}/get/alumnus`;
        return apiService.get(url);
    }

}