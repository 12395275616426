<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>  Manage Alumnus</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \  Alumnus
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('alumnus', 'pending-approval')"
                      @click="managePendingAlumnus()"
                      x-large
                      text
                  >
                   Pending approval
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('alumnus', 'create')" @click="createAlumnus()" class="mt-1 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add alumni
                  </v-btn>&nbsp;&nbsp;

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                      class="form-control"
                      v-model="search.name"
                      label="Name, email"
                      outlined
                      clearable
                      @input="search.name = $event !== null ? $event : ''"
                      v-on:keyup.enter="getAllAlumni()"
                      dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                      :items="academic_years"
                      class="form-control"  :menu-props="{ button: true, offsetY: true }"
                      v-model="search.academic_year_id"
                      label="Academic year"
                      item-value="id"
                      item-text="year"
                      @input="search.academic_year_id = $event !== null ? $event : ''"
                      outlined  v-on:keyup.enter="getAllAlumni()"
                      clearable
                      dense>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select outlined dense
                            v-model="search.program_id"
                            :items="programs"
                            label="Programs"
                            @input="search.program_id = $event !== null ? $event : ''"
                            item-value="id"  :menu-props="{ button: true, offsetY: true }"
                            item-text="title"
                            v-on:keyup.enter="getAllAlumni()"
                            clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                      @click="getAllAlumni()"
                      class="btn btn-primary btn-search w-35"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                  <tr class="px-3">

                    <th class="px-3 wrap-column">Full name</th>
                    <th class="px-3 wrap-column">Email</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-show="alumni.length > 0" v-for="(alumnus,index) in alumni" :key="index">

                      <td class="px-3 wrap-column">
                        <span class="mb-1 font-weight-medium">
                          {{alumnus.first_name }} {{ alumnus.middle_name }} {{ alumnus.last_name }}
                        </span>
                      </td>
                      
                      <td class="wrap-column px-3">
                        <div class="text--secondary">
                          <a class="text-info mb-1" :href="'mailto:'+alumnus.email">
                            {{alumnus.email ? alumnus.email : 'NA' }}
                          </a>
                        </div>
                      </td>

                      <td class="px-3 wrap-column">
                        <div class="mt-2">
                          <span class="badge  text-lg "
                                v-bind:class="{ 'badge-success': alumnus.is_active, 'badge-danger': !alumnus.is_active }">
                            {{ alumnus.is_active ? 'Active' : 'Inactive' }}
                          </span>
                        </div>
                        <div class="mb-3 mt-2">
                          <span class="badge  text-lg "
                                v-bind:class="{ 'badge-primary': alumnus.approved_status=='approved', 'badge-warning': alumnus.approved_status=='rejected' }">
                            {{ alumnus.approved_status_text }}
                          </span>
                        </div>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text v-if="checkIsAccessible('alumnus', 'edit')" tag="div" class="navi-item">
                                <a class="navi-link" @click="editAlumnusAddress(alumnus)">
                                  <span class="navi-icon">
                                       <i class="fas fa-home"></i>
                                  </span>
                                  <span class="navi-text">Update address</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text v-if="checkIsAccessible('alumnus', 'edit')" tag="div" class="navi-item">
                                <a class="navi-link" @click="editAlumnusWorkExperience(alumnus.id)">
                                  <span class="navi-icon">
                                       <i class="fas fa-briefcase"></i>
                                  </span>
                                  <span class="navi-text">Update work experience</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text v-if="checkIsAccessible('alumnus', 'edit')" tag="div" class="navi-item">
                                <a class="navi-link" @click="editAlumnus(alumnus.id)">
                                  <span class="navi-icon">
                                       <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text v-if="checkIsAccessible('alumnus', 'delete')" tag="div" class="navi-item">
                                <a class="navi-link" @click="deleteAlumnus(alumnus.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-show="alumni.length==0">
                      <td class="text-center" colspan="5">
                        <strong>No data available to display.</strong>
                      </td>
                    </tr>
                  </tbody>

              </table>
              <b-pagination
                  v-if="alumni.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAlumni"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number :loading="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

      <v-row justify="center" v-if="dialog">
        <v-dialog
            v-model="dialog"
            max-width="800"
        >
          <v-card>
            <v-card-title class="text-h5">
              Update address
            </v-card-title>

            <v-card-text>
              <div class="row mb-3">
                    <div class="col-12">
                      <v-text-field v-model="alumnusDetail.address_line_1" dense outlined  placeholder="Address line 1" ></v-text-field>
                    </div>

                    <div class="col-12">
                      <v-text-field v-model="alumnusDetail.address_line_2" placeholder="Address line 2" dense outlined  ></v-text-field>
                    </div>

                    <div class="col-12">
                      <v-text-field v-model="alumnusDetail.city" dense outlined  placeholder="City" ></v-text-field>
                    </div>

                    <div class="col-12">
                      <v-text-field v-model="alumnusDetail.state" placeholder="State" dense outlined  ></v-text-field>
                    </div>

                    <div class="col-12">
                      <v-autocomplete v-model="alumnusDetail.country_id" placeholder="Country" dense outlined :items="countries" item-value="id" item-text="title" ></v-autocomplete>
                    </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  class="btn btn-standard text-gray cancel-btn"
                  text
                  x-large
                  @click="closeDialog"
              >
                Cancel
              </v-btn>

              <v-btn
                  class="text-white btn btn-primary btn  px-9 py-4 my-3 font-size-3 mx-4"
                  dark
                  @click="updateAddress"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" v-if="dialog1">
        <v-dialog
            v-model="dialog1"
            max-width="800"
        >
          <v-card>
            <v-card-title class="text-h5">
              Update work experience
            </v-card-title>

            <v-card-text>

              <div class="row mb-3">
                    <div class="col-12">
                      <v-text-field v-model="workExperience.title" dense outlined  :error="$v.workExperience.title.$error" placeholder="Title" ></v-text-field>
                      <span class="text-danger" v-if="$v.workExperience.title.$error">This information is required.</span>
                    </div>

                    <div class="col-12">
                      <v-text-field v-model="workExperience.company" placeholder="Company"  :error="$v.workExperience.company.$error" dense outlined  ></v-text-field>
                      <span class="text-danger" v-if="$v.workExperience.company.$error">This information is required.</span>
                    </div>

                    <div class="col-12">
                      <v-autocomplete v-model="workExperience.country_id" placeholder="Country" dense outlined :items="countries" item-value="id" item-text="title" ></v-autocomplete>
                    </div>

                    <div class="col-12">
                      <v-autocomplete v-model="workExperience.start_year" placeholder="Start year" dense outlined :items="years" :error="$v.workExperience.start_year.$error" ></v-autocomplete>
                      <span class="text-danger" v-if="$v.workExperience.start_year.$error">This information is required.</span>
                    </div>

                    <div class="col-12">
                      <v-autocomplete v-model="workExperience.end_year" placeholder="End year" dense outlined :items="years" ></v-autocomplete>
                    </div>
              </div>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  class="btn btn-standard text-gray cancel-btn"
                  text
                  x-large
                  @click="closeDialog"
              >
                Cancel
              </v-btn>

              <v-btn
                  class="text-white btn btn-primary btn  px-9 py-4 my-3 font-size-3 mx-4"
                  dark
                  @click="updateWorkExperience"
              >
                {{workExperience.id ? "Update" : "Save" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  </v-app>
</template>
<script>
import {required, numeric} from "vuelidate/lib/validators";
import AlumnusService from "@/core/services/alumnus/AlumnusService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import CountryService from "@/core/services/address/CountryService";
import AlumnusWorkExperienceService from "@/core/services/alumnus/work-experience/AlumnusWorkExperienceService";

const academicYearService=new AcademicYearService();
const program=new ProgramService();
const alumnus=new AlumnusService();
const country = new CountryService();
const alumnusWorkExperience = new AlumnusWorkExperienceService();
export default {
  data(){
    return{
      loading: false,
      dialog: false,
      dialog1: false,
      alumni:[],
      countries:[],
      years:[],
      search:{
        name:'',
        program_id:null,
        academic_year_id:null,
        approved_status:'not_pending'
      },
      page: null,
      total: 0,
      totalPage: null,
      perPage:0,
      academic_years:[],
      programs:[],
      programLoading:false,
      academicYearLoading:false,
      alumnusDetail: {},
      workExperience: {},
      currYear: new Date().getFullYear(),
    }
  },
  validations: {

    workExperience: {
      title:{required},
      company:{required},
      start_year:{required,numeric},
    }
  },
  mounted() {
    this.getAllPrograms();
    this.getAcademicYears();
    this.getAllCountries();
    this.getAllAlumni();
    this.buildYears();
  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.dialog1 = false;
      this.$v.$reset();
    },

    getAllAlumni(){
      this.loading=true;
      alumnus
          .paginate(this.search,this.page)
          .then(response => {
             this.alumni=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.totalPage = response.data.meta.last_page;
              this.perPage = parseInt(response.data.meta.per_page);
          })
          .catch(() => { })
          .finally(() => {
            this.loading=false;
          });
    },

    buildYears(){
      this.years=[];
      for (let i=1970; i <= this.currYear; i++) {
        this.years.push(i);
      }
      this.years.push('Present');
    },

    createAlumnus(){
      this.$router.push({
        name:"alumnus-create",
      });
    },

    managePendingAlumnus(){
      this.$router.push({
        name:'pending-alumnus',

      })
    },

    editAlumnus(alumnusId){
      this.$router.push({
        name:'alumnus-edit',
        params:{alumnusId:alumnusId}
      })
    },

    editAlumnusAddress(alumnus){
      this.dialog = true;
      this.alumnusDetail = alumnus;
    },

    editAlumnusWorkExperience(alumnusId){
      this.dialog1 = true;
      this.workExperience.alumnus_id = this.workExperience.alumnus_id ? this.workExperience.alumnus_id : alumnusId;
      this.getWorkExperience(alumnusId);
    },

    getAllCountries(){
      country
          .all()
          .then((response) => {
            this.countries=response.data;
          })
          .catch(() => {
          });
    },

    updateAddress() {
      alumnus
          .update(this.alumnusDetail.id, this.alumnusDetail)
          .then(response => {
            this.$snotify.success("Update address successfully!!");
            this.getAllAlumni();
            this.closeDialog();
          })
          .catch(() => {
          });
    },

    getWorkExperience(alumnusId) {
      alumnusWorkExperience
          .showByAlumnus(alumnusId)
          .then(response => {
            if(response.data.alumnusWorkExperience) {
              this.workExperience = response.data.alumnusWorkExperience;
            }
          }).catch(() => {
          });
    },

    updateWorkExperience() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        alumnusWorkExperience
            .createOrUpdate(this.workExperience)
            .then(response => {
              this.$snotify.success("Update ork experience!!");
              this.closeDialog();
            })
            .catch(() => {
            });
      }
    },

    deleteAlumnus(alumnusId){
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            alumnus
                .delete(alumnusId)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getAllAlumni();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    openSummaryPage(item){
      if(this.checkIsAccessible('user', 'list')) {
        this.$router.push({
          name: "students-summary",
          params: { id:item.user_id, type:'student' }
        });
      }
    },
    getAllPrograms(){
      this.programLoading=true;
      program
          .all()
          .then(response => {
            this.programs=response.data;
          })
          .catch(error => {

          })
          .finally(() => {
            this.programLoading=false;
          });
    },
    getAcademicYears() {
      this.academicYearLoading=true;
      academicYearService
          .all()
          .then(response => {
            this.academic_years = response.data;
          })
          .catch(error => {

          })
          .finally(() => {
            this.academicYearLoading=false;
          });
    },
  },

}
</script>